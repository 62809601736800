<template>
  <div class="col-12 main-info-money overflow-hidden p-0 pt-4 ">
    <div class="d-flex justify-content-end pr-3 pl-3">
      <button v-for="(key, currency) in currency_list" style=" width: 4rem;" class="btn m-1"
              :key="key"
              @click="handleCurrency(currency.toUpperCase())"
              :class="{
        'btn-outline-primary': dataPay.currency.toUpperCase() !== currency.toUpperCase(),
        'btn-primary': dataPay.currency.toUpperCase() === currency.toUpperCase()
              }"
      >
        {{ currency.toUpperCase() }}
      </button>
    </div>
    <div class="input-group mb-3 pr-3 pl-3 position-relative">
      <input @input="handleData($event.target.value, 'price')" :value="dataPay.price"
             style="font-size: 20px; color: red; text-align: center; border-radius: 15px" type="text" class="form-control"
             :placeholder="$t('messages.amount_example') + dataPay.currency.toUpperCase()"
             :class="{
               'border-danger': error
             }"
             aria-describedby="basic-addon1">
      <span class="text-danger position-absolute" style="bottom: -20px">
        <strong v-if="error" >{{ $t('messages.wrong_amount') }}</strong>
      </span>
    </div>
    <div class="input-group mb-3 pr-3 pl-3 pt-2 position-relative">
      <input @input="handleData($event.target.value, 'username')" :value="dataPay.username"
             style="border-radius: 15px; font-size: 20px; line-height: 25px" type="text" class="form-control p-3"
             :placeholder="$t('messages.user_name')" aria-describedby="basic-addon1">
      <div style="background: white; right:30px; bottom: -5px; width: 80px; z-index:10;border-radius: 15px;"
           class="position-absolute d-flex justify-content-start">
        <input v-model="userAnonymous"
               style="height: auto;cursor: pointer; box-shadow: none; position: relative; left: -5px" id="anonymous"
               type="checkbox">
        <label class="w-100 d-flex mb-0"
               style="font-size:10px; line-height: 12px; cursor: pointer; position: relative; right: 5px"
               for="anonymous">{{ $t('messages.anonymous') }}</label>
      </div>
    </div>
    <div class="input-group mb-3 pr-3 pl-3 pt-2">
      <textarea @input="handleData($event.target.value, 'comment')" style="font-size: 20px;" class="form-control"
                aria-label="With textarea" :placeholder="$t('messages.words_of_support')"></textarea>
    </div>
    <!--     Button trigger modal -->
    <button @click="createStripeSession" type="button"
            class="btn-donate w-100 d-flex justify-content-center align-items-center p-3 btn"
            data-toggle="modal" data-target="#staticBackdrop">
      <div class="container-heart d-flex align-items-center justify-content-center">
        <i class="fas fa-heart text-danger"></i>
      </div>
      {{ $t('messages.support_now') }}
    </button>


    <div v-if="load" class="position-absolute w-100" style="top: 0">
      <div class="loader" style=""></div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import axios                        from "axios";

export default {
  name: "BeforePay",
  components: {},
  data() {
    return {
      error: false,
      userAnonymous: false,
      load: false,
      currency_list: {
        usd: 'USD',
        eur: 'EUR',
        pln: 'PLN',
        gbp: 'GBP',
        // uah: 'UAH',
        // rub: 'RUB',
      }
    }
  },
  computed: {
    ...mapGetters({
      dataPay: 'dataPay',
      stripe: 'stripe',
    })
  },
  watch: {
    userAnonymous(prev, next) {
      if (prev !== next) {
        if (!next)
          this.SET_DATA_PAY({...this.dataPay, username: this.$t('messages.anonymous_benefactor')});
      }
    },
  },
  created() {
    this.SET_DATA_PAY({...this.dataPay, username: this.$t('messages.anonymous_benefactor')});
    console.log(this.dataPay)
  },
  methods: {
    ...mapMutations({
      SET_DATA_PAY: 'SET_DATA_PAY',
      SET_VIEW_MODAL_PAY: 'SET_VIEW_MODAL_PAY',
    }),
    handleCurrency(currency) {
      this.SET_DATA_PAY({...this.dataPay, currency});
    },
    handleData(value, name) {
      if (name === 'username') {
        this.userAnonymous = false;
      }
      if (name === 'price') {
        value = value.toString().replace(/\D+/, '') ?? ''
      }
      let tmp = {...this.dataPay};
      tmp[name] = value;
      this.SET_DATA_PAY(tmp);
    },
    createStripeSession() {

      //validate
      if (!this.dataPay.username.length) {
        this.SET_DATA_PAY({...this.dataPay, username: this.$t('messages.anonymous_benefactor')});
      }
      if (!this.validatePrice()) {
        return;
      }

      this.load = true;

      // eslint-disable-next-line no-undef
      let stripe = Stripe(this.stripe.publicKey);
      axios.post('https://api.mariannes-life.com/stripe/session/create', this.dataPay)
          .then(({data}) => {

            localStorage.setItem('paid', JSON.stringify({
              sess_id: data,
              status: 0,
              view: 0,
            }))

            return stripe.redirectToCheckout({sessionId: data});
          })
          .then((result) => {
            this.load = false;
            // If `redirectToCheckout` fails due to a browser or network
            // error, you should display the localized error message to your
            // customer using `error.message`.
            if (result.error) {
              alert(result.error.message);
            }
          })
          .catch((error) => {
            this.load = false;
            console.error('Error:', error);
          });
    },
    validatePrice() {
      if (this.dataPay.currency.toUpperCase() === 'GBP' && +this.dataPay.price < 1) {
        this.error = true;
        return false;
      }
      if (this.dataPay.currency.toUpperCase() === 'USD' && +this.dataPay.price < 1) {
        this.error = true;
        return false;
      }
      if (this.dataPay.currency.toUpperCase() === 'EUR' && +this.dataPay.price < 1) {
        this.error = true;
        return false;
      }
      if (this.dataPay.currency.toUpperCase() === 'PLN' && +this.dataPay.price < 2) {
        this.error = true;
        return false;
      }
      return true;
    }
  }
}
</script>

<style scoped lang="scss">

.input-group {
  input, textarea {
    background: #FFFFFF;
    border: 1px solid #D4C9C9;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
  }
}

.main-info-money {
  background: #F5FEF3;
  border-radius: 20px;

  .first-part {
    font-size: 23px;
    line-height: 27px;
    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .second-part {
    font-size: 30px;
    line-height: 35px;
    font-weight: bold;
  }

  .third-part {
    font-size: 20px;
    line-height: 23px;
    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.money-count {
  background: rgba(183, 216, 174, 0.67);
  border-radius: 20px;
  margin: 0 23px;

  p {
    font-weight: bold;
  }
}

.btn-donate {
  cursor: pointer;
  background-color: #C01414 !important;
  color: white !important;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  animation-name: heart-bit;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  @media (max-width: 1023px) {
    font-size: 20px;
    line-height: 25px;
  }
}

.container-heart {
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 100%;
  margin-right: 10px;

  i {
    font-size: 20px;
    animation-name: heart-bit;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    animation-duration: 3s;

    @media (max-width: 1023px) {
      font-size: 15px;
    }
  }

  @media (max-width: 1023px) {
    width: 30px;
    height: 30px;
  }
}
</style>