const messages = {
    uk: {
        messages: {
            name_charitable_organization: 'Збережи життя Маріанни',
            header_title: 'Маріанка бореться за своє життя! Найдорожчі ліки у світі можуть врятувати її від смерті!',
            collection_description: {
                title: 'Опис збору',
                text1: `Наше найбільше щастя прийшло у світ у жовтні 2018 року.
        Ця маленька дівчинка відразу стала коханням нашого життя!`,
                text2: `Ми були надзвичайно щасливі, адже поява на світ нашої жаданої донечки внесла багато радості в нашу родину.
      Щодня ми спостерігали, як вона дізнавалась про нові речі, як розвивалась.`,
                text3: `Ми помітили в 7-8 місяців що наша дівчинка не навчилася стояти, лікарі заспокоювали і говорили що потрібно зачекати, 
                оскільки вона народилася з великою вагою - майже 5 кг. Але вона і в один рік не могла стояти і робити перші кроки. Наші 
                походи до спеціалістів почалися з 9 місяців життя нашої донечки, які ставили нам різні діагнози: гіпотонію і дисплазію 
                тазобедренних суглобів, але ніхто не запідозрив спинно м’язозу атрофію і ніхто не призначив пройти генетичний аналіз.
                Минали дні, і замість того, щоб бути дедалі жвавішою, Маріанна ставала млявішою і менш рухомою. Ми втратили багато дорогоцінного часу.`,
                text4: `І тільки через 1 рік і 3 місяці лікар назначив ще пройти генетичний тест, який ми чекали як
        порятунку, але це виявилось прокляттям.`,
                text5: ``,
                text6: `Діагноз - СМА другого типу. Весь наш світ за секунду розвалився.`,
                text7: `Дивлячись в прекрасні очі донечки ми не могли повірити, що хвороба намагається її забрати від нас.
      Всі плани і мрії зруйнувалися. `,
                text8: `Зараз мета лише одна – врятувати нашу дівчинку. За будь-яку
      ціну.`,
                text9: `Спинна м'язова атрофія - одне з найжорстокіших генетичних захворювань,
      яке змушує організм нашої дочки не виробляти білок, який будує нервові клітини в спинному мозку,
      нерви дегенерують, не посилають імпульсів м’язам, а ті з часом зменшуються.`,
                text10: `Небезпека SMA в тому, що руйнуються всі м’язи, не тільки ті, які відповідають за рух,
        але і ті, які відповідають за ковтання, дихання, життя...`,
                text11: `В Україні лікування немає!`,
                text12: `Не замислюючись ні на мить ми переїхали до Польщі,
      адже саме тут Маріанна отримує препарат «Спінраза» який є підтримуючою терапією.
      Але лікування цим препаратом тільки призупиняє хворобу,
      і потрібно його колоти на протязі всього життя один раз на чотири місяці,
      ця процедура дуже болісна(колять в спинний мозок).`,
                text13: `Є порятунок! Це нова, інноваційна генна терапія, яка проводиться в США одноразово та на все
        життя.`,
                text14: ` Завдяки їй організм починає виробляти білок, необхідний для роботи м’язів (через відсутність якого м’язи
      Маріанки гинуть).
      Однак лікування коштує немислиму суму – `,
                text15: `понад 2.300.000 доларів.`,
                text16: `Це шанс, що наша дитина
      проживе нормальне життя.`,
                text17: `  Ми просимо всіх вас, хто читає це звернення.
      Ми просимо всіх людей із добрими серцями про допомогу в порятунку нашої дочки.
      Ми знаємо, що просимо багато - це космічна сума.
      Однак ми віримо в диво, іншого вибору у нас немає.`,
                text18: `У нас не так багато часу, ми дуже боїмося.
        Маріанка має шанс жити, жити нормально!
        Будь ласка, допоможіть нам дати їй надію...`,
                text19: `Не існує більших страждань, ніж ті, які відчуває мати, чия дитина бореться за своє життя.`,
            },
            documentations: 'Документація',
            benefactors: 'Добродійники',
            anonymous_benefactors: 'Анонімний добродійник',
            displayed_more: 'показати більше',
            fundraising: 'Дуже терміновий збір коштів!',
            amount_of_the_fee: 'Сума збору',
            supported: 'Підтримали',
            support: 'Підтримати',
            support_now: 'Пожертвуйте зараз',
            persons: 'осіб',
            money_not_enough: `все ще не вистачає! Не будьте байдужими! Допоможіть!`,
            owner_name: 'Михайлова Маріанна Артурівна',
            diagnosis: 'Діагноз',
            sma: "СМА спинально - м'язова атрофія 2 типу",
            purpose_of_the_collection: "Призначення збору",
            collection_zolgensma: 'Збір коштів на препарат "Золгенсма"',
            location: 'Населений пункт',
            location_name: 'Україна, Бровари, Київська область',
            beginning: 'Початок',
            beginning_date: '09 січня 2021',
            social_media: 'Соціальні медіа',
            anonymous_benefactor: 'Анонімний добродійник',
            amount_example: 'Сума напр. 10 ',
            user_name: 'Твоє ім\'я',
            anonymous: 'Анонімно',
            words_of_support: 'Слова підтримки',
            thank_donat: 'Дякуюємо за вашу пожертву!',
            wrong_amount: 'Неправильна сума',
        }
    },
    ru: {
        messages: {
            name_charitable_organization: 'Сохрани жизнь Марианны',
            header_title: 'Марианка борется за свою жизнь! Самые дорогие лекарства в мире могут спасти ее от смерти!',
            collection_description: {
                title: 'Описание сбора',
                text1: `Наше самое большое счастье пришло в свет в октябре 2018 года.
         Эта маленькая девочка сразу стала любовью нашей жизни!`,
                text2: `Мы были очень счастливы, ведь появление на свет нашей желанной дочери внесла много радости в нашу семью.
       Ежедневно мы наблюдали, как она узнавала о новых вещах, как развивалась.`,
                text3: `Мы заметили в 7-8 месяцев что наша девочка не научилась стоять, врачи успокаивали и говорили что нужно подождать, 
        т.к. она родилась с большим весом почти 5 кг. Но она и в годик не смогла стоять и сделать первые шаги.
        Наши походы к специалистам начались с девяти месяцев жизни нашей дочери, которые ставили нам разные диагнозы:
        гипотонию и дисплазию тазобедренных суставов, но никто не заподозрил спинальную мышечную атрофию и не назначил пройти генетический анализ. 
        Шли дни, и вместо того, чтобы быть все более живой, Марианна становилась вялой и менее подвижной. Мы потеряли много драгоценного времени.`,
                text4: `И только через 1 год и 3 месяца врач назначил еще пройти генетический тест, который мы ждали как
         спасения, но это оказалось проклятием.`,
                text5: ` `,
                text6: `Диагноз - СМА второго типа. Весь наш мир в секунду развалился.`,
                text7: `Глядя в прекрасные глаза дочери мы не могли поверить, что болезнь пытается ее забрать от нас. Все планы и мечты разрушились.`,
                text8: `Сейчас цель только одна - спасти нашу девочку. Любой ценой.`,
                text9: `Спинная мышечная атрофия - одно из самых жестоких генетических заболеваний,
       которое заставляет организм нашей дочери не производить белок, который строит нервные клетки в спинном мозге,
       нервы дегенерируют, не посылают импульсов мышцам, а те со временем уменьшаются.`,
                text10: `Опастность SMA в том, что разрушаются все мышцы, не только те, которые отвечает за движение,
        но и те, который отвечают за глотание, дыхание, жизнь ...`,
                text11: `В Украине лечения нет!`,
                text12: `Не задумываясь ни на минуту мы переехали в Польшу,
       ведь именно здесь Марианна получает препарат «Спинраза», который является поддерживающей терапией.
       Но лечение этим препаратом только приостанавливает болезнь,
       и нужно его колоть на протяжении всей жизни один раз в четыре месяца,
       эта процедура очень болезненная (колят в спинной мозг).`,
                text13: `Есть спасение! Это новая, инновационная генная терапия, которая проводится в США единоразово и на всю
         жизнь.`,
                text14: `Благодаря ей организм начинает вырабатывать белок, необходимый для работы мышц (из-за отсутствия которого мышцы Марианки погибают).
       Однако лечение стоит немыслимую сумму - `,
                text15: `более 2.300.000 долларов.`,
                text16: `Это шанс, что наш ребенок проживет нормальную жизнь.`,
                text17: `Мы просим всех вас, кто читает это обращение.
       Мы просим всех людей с добрыми сердцами о помощи в спасении нашей дочери.
       Мы знаем, что просим много - это космическая сумма.
       Однако мы верим в чудо, другого выбора у нас нет.`,
                text18: `У нас не так много времени, мы очень боимся.
         Марианка имеет шанс жить, жить нормально!
         Пожалуйста, помогите нам дать ей надежду...`,
                text19: `Не существует больших страданий, чем те, которые испытывает мать, чей ребенок борется за свою жизнь.`,
            },
            documentations: 'Документация',
            benefactors: 'Благотворители',
            anonymous_benefactors: 'Анонимный благотворитель',
            displayed_more: 'показать больше',
            fundraising: 'Очень срочный сбор средств!',
            amount_of_the_fee: 'Сумма сбора',
            supported: 'Поддержали',
            support: 'Поддержать',
            support_now: 'Пожертвуйте сейчас',
            persons: 'лиц',
            money_not_enough: `все еще не хватает! Не будьте равнодушными! Помогите!`,
            owner_name: 'Михайлова Марианна Артуровна',
            diagnosis: 'Диагноз',
            sma: "СМА спинально - мышечная атрофия 2 типа",
            purpose_of_the_collection: "Назначение сбора",
            collection_zolgensma: 'Сбор средств на препарат "Золгенсма"',
            location: 'Населенный пункт',
            location_name: 'Украина, Бровары, Киевская область',
            beginning: 'Начало',
            beginning_date: '09 января 2021',
            social_media: 'Социальные медиа',
            anonymous_benefactor: 'Анонимный благотворитель',
            amount_example: 'Сумма напр. 10 ',
            user_name: 'Твое имя',
            anonymous: 'Анонимно',
            words_of_support: 'Слова поддержки',
            thank_donat: 'Спасибо за ваше пожертвование!',
            wrong_amount: 'Неправильная сумма',
        }
    },
    en: {
        messages: {
            name_charitable_organization: 'Save Marianne\'s Life',
            header_title: 'Marianka is fighting for her life! The most expensive drugs in the world can save her from death!',
            collection_description: {
                title: 'Fundraising Description',
                text1: `Our greatest happiness came in October 2018. This little girl immediately became the love of our life! `,
                text2: `We were very happy, because the birth of our desired daughter brought a lot of joy to our family. 
Every day we watched how she learned about new things, how she developed. `,
                text3: `At 7-8 months we noticed that our girl did not start to stand, the doctors reassured us and said that we had
 to wait, because she was born with a heavy weight of almost 5 kg. But even when she was one year old 
she could not stand and take the first steps.
Our visits to specialists began when our daughter was nine months old, who gave us different diagnoses:
hypotension and dysplasia of the hip joints, but no one suspected spinal muscular atrophy and ordered a genetic test.
The days went by, and instead of being more and more alive, Mariannа became lethargic and less mobile. 
We've lost a lot of precious time.`,
                text4: `And only after 1 year and 3 months, the doctor prescribed a genetic test, which we were waiting for as
salvation, but it turned out to be a curse.`,
                text5: ` `,
                text6: `Diagnosis - SMA Type II . Our world fell apart in the second.`,
                text7: `Looking into our daughter's beautiful eyes, we could not believe that the disease was trying to take her away from us.
All plans and dreams have collapsed.`,
                text8: `Now there is only one aim - to save our girl. At any cost.`,
                text9: `Spinal muscular atrophy (SMA) is one of the most severe genetic diseases
which causes our daughter's body not to produce the protein that builds nerve cells in the spinal cord,
nerves degenerate, do not send impulses to the muscles, and they decrease over time.`,
                text10: `The danger of SMA is that all muscles are slowly destroyed, not only those responsible for movement,
but also those responsible for swallowing, breathing, life ...`,
                text11: `There is no treatment in Ukraine!`,
                text12: `Without thinking for a minute we moved to Poland,
where Marianna receives Spinraza, which is a maintenance therapy.
But treatment with this drug only slows down the disease,
and it needs to be injected throughout its life once every four months,
this procedure is very painful (injections into the spinal cord).`,
                text13: `There is salvation! This is a new, innovative gene therapy, which is carried out in the United States once and for the whole
a life.`,
                text14: `Thanks to her, the body begins to produce protein necessary for muscle work (due to the lack of which muscles
  Marianas die).
However, the treatment costs an incredible amount -`,
                text15: `more than 2.300.000 dollars.`,
                text16: `This is a chance that our child will live a normal life.`,
                text17: `We ask all of you who are reading this appeal.
We ask all people with good hearts for help in saving our daughter.
We know that we are asking for a lot - this is a cosmic amount.
However, we believe in miracles, we have no choice.`,
                text18: `We don't have much time, we are very afraid. Treatment is possible up to 21 kg of weight, now the weight is 13 kg
Marianka has a chance to live, live a normal life!
Please help us give her hope ...`,
                text19: `There is no greater suffering than those experienced by a mother whose child is fighting for his life.`,
            },
            documentations: 'Documentation',
            benefactors: 'Donator',
            anonymous_benefactors: 'Anonymous donator',
            displayed_more: 'show more',
            fundraising: 'A very urgent fundraiser!',
            amount_of_the_fee: 'Fundraising amount',
            supported: 'Donated',
            support: 'Donate',
            support_now: 'Donate now',
            persons: 'persons',
            money_not_enough: `still not enough funds! Don't be indifferent! Help!`,
            owner_name: 'Mikhailova Marianna Arturovna',
            diagnosis: 'Diagnosis',
            sma: "SMA spinal muscular atrophy type 2",
            purpose_of_the_collection: "Purpose of fundraising",
            collection_zolgensma: 'Fundraising for the drug "Zolgensma"',
            location: 'Locality',
            location_name: 'Ukraine, Brovary, Kiev region',
            beginning: 'Fundraising start',
            beginning_date: '09 January 2021',
            social_media: 'Social media',
            anonymous_benefactor: 'Anonymous benefactor',
            amount_example: 'Amount example 10 ',
            user_name: 'Your name',
            anonymous: 'Anonymously',
            words_of_support: 'Words of support',
            thank_donat: 'Thanks for your donation!',
            wrong_amount: 'Wrong amount',
        }
    }
}

export default messages;