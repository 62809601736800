<template>
  <!-- Modal -->
  <div class="modal fade" id="paidModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
       aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
<!--        <div class="modal-header">-->
<!--          <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
<!--            <span aria-hidden="true">&times;</span>-->
<!--          </button>-->
<!--        </div>-->
        <div class="modal-body">
          <div class="w-100 h-100 position-relative ">
            <img class="w-100" src="/files/heart.png" alt="">
            <div class="blur"></div>
            <span class="thank-text">{{ $t('messages.thank_donat') }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="hideModal" type="button" class="btn  btn-outline-primary" data-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex';

export default {
  name: "Modal",
  data() {
    return {
      maxHeight: window.screen.availHeight * 85 / 100,
      modalOpacity: false,
    }
  },
  computed: {
    ...mapGetters({
      viewModalPay: 'viewModalPay',
      paidInfo: 'paidInfo',
    })
  },
  watch: {
    viewModalPay() {
      this.viewModalOpacity(this.viewModalPay);
    }
  },
  methods: {
    ...mapMutations({
      SET_VIEW_MODAL_PAY: 'SET_VIEW_MODAL_PAY',
    }),
    hideModal() {
      // eslint-disable-next-line no-undef
      $('#paidModal').modal('hide');
    },
    close() {
      this.SET_VIEW_MODAL_PAY(!this.viewModalPay);
    },
    viewModalOpacity(viewModal) {
      setTimeout(() => {
        this.modalOpacity = viewModal;
      }, 10)
    }
  },
  mounted() {
    console.log(this.maxHeight)
  },
  created() {}
}
</script>

<style scoped lang="scss">
button {
  width: unset;
}

//.modal {
//  display: flex!important;
//  align-items: center;
//}

.blur {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(128,124,125,0.58);
}

.modal-body {

  position: relative;

  div {
    border-radius: 20px;
  }

  img {
    border-radius: 20px;
  }

  span {
    position: absolute;
    left: 10px;
    bottom: 30px;
    width: 75%;
  }
}

.modal-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
}

.credit-card-name {
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  color: #9E9B9B;
}

.thank-text {
  font-size: 30px;
  line-height: 35px;
  text-align: center;

  color: #FFFFFF;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

//background: #F5FEF3;
//border-radius: 20px;

</style>