import Vue                 from 'vue'
import App                 from './App.vue'
import router              from './router'
import store               from './store'
import { library }         from '@fortawesome/fontawesome-svg-core'
import { faUserSecret }    from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueI18n             from "vue-i18n";
import VueCurrencyFilter from 'vue-currency-filter'
import messages            from '@/locales/text'
Vue.use(VueI18n)
library.add(faUserSecret)

Vue.use(VueCurrencyFilter,
    {
        symbol : '',
        thousandsSeparator: ' ',
        fractionCount: 0,
        fractionSeparator: ',',
        symbolPosition: 'front',
        symbolSpacing: true,
        avoidEmptyDecimals: undefined,
    })

Vue.component('font-awesome-icon', FontAwesomeIcon)

const i18n = new VueI18n({
    locale: 'en', // установка локализации по умолчанию
    messages // установка сообщений локализаций
})

import '@/assets/style/app.scss';
import '@/assets/bootstrap-4.5.3-dist/css/bootstrap.min.css';

Vue.config.productionTip = false

new Vue({
    router,
    i18n,
    store,
    render: h => h(App),
    mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
