<template>
  <div id="app">
    <div class="container-fluid">
      <HeaderView/>
      <MainView/>
      <FooterView/>
      <Modal/>
      <ModalPay/>
    </div>
  </div>
</template>

<style lang="scss">

</style>

<script>
import HeaderView     from "@/views/Header/HeaderView";
import MainView       from "@/views/Main/MainView";
import FooterView     from "@/views/Footer/FooterView";
import Modal          from "@/components/shared/Modal";
import ModalPay       from "@/components/shared/ModalPay";
import axios          from "axios";
import { mapActions } from "vuex";

export default {
  name: 'App',
  components: {
    Modal,
    FooterView,
    MainView,
    HeaderView,
    ModalPay,
  },
  created() {
    this.getTotalPaid();
    this.getUserList();
    this.getStripe();
    this.handleUser();
    setInterval(() => {
      this.getTotalPaid();
      this.getUserList();
    }, 10000)

  },
  methods: {
    ...mapActions({
      getTotalPaid: 'getTotalPaid',
      getUserList: 'getUserList',
      setPaidInfo: 'setPaidInfo',
      getStripe: 'getStripe',
      setLocale: 'setLocale',
    }),
    handleUser() {
      axios
          .get('https://api.mariannes-life.com/handleUser')
          .then(({data}) => {
            let locale = data.location.languages[0].code ?? 'en';
            const availableLang = ['en', 'uk', 'ru'];
            if (!availableLang.includes(locale))
              locale = 'en';
            this._i18n.locale = locale;
            this.setLocale(locale);
          });
    },
  },
  mounted() {
    let paidInfo = localStorage.getItem('paid');
    if (!paidInfo) {
      paidInfo = {
        sess_id: '',
        status: 0,
        view: 0,
      }
      localStorage.setItem('paid', JSON.stringify(paidInfo))
    } else {
      paidInfo = JSON.parse(paidInfo);
      if (paidInfo.sess_id && !+paidInfo.view) {
        axios.get('https://api.mariannes-life.com/getUser', {
          params: {
            sess_id: paidInfo.sess_id,
          }
        }).then(({data}) => {
          if (+data.status) {
            paidInfo.status = +data.status;
            paidInfo.view = 1;
            localStorage.setItem('paid', JSON.stringify(paidInfo));
            // eslint-disable-next-line no-undef
            $('#paidModal').modal('show');
          }
        })
      }
    }
    this.setPaidInfo(paidInfo);
  }
}
</script>
