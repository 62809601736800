<template>
  <div id="footer" class="container-fluid mt-4">
    <div class="row no-gutters">
      <div class="offset-1 d-none d-lg-flex"></div>
      <div class="col-12 col-md-10 col-lg-4">
        <div class="row pt-4 pb-4">
          <div class="col-4 col-lg-6 d-none d-md-flex align-items-center">
            <h5 class="text-white">{{ $t('messages.social_media') }}</h5>
          </div>
          <div
              class="col-5 col-md-3 col-lg-6 d-flex justify-content-md-between justify-content-lg-start align-items-center">
            <a href="https://www.facebook.com/SaveMariannaSMA/" target="_blank" class="text-dark">
              <div class="social-circle d-flex justify-content-center align-items-center mr-lg-2">
                <i class="fab fa-facebook-f"></i>
              </div>
            </a>
            <a href="https://instagram.com/save_marianna_sma" target="_blank" class="text-dark">
              <div class="social-circle d-flex justify-content-center align-items-center mr-lg-2">
                <i class="fab fa-instagram-square"></i>
              </div>
            </a>
            <a href="#" target="_blank" class="text-dark">
<!--              <div class="social-circle d-flex justify-content-center align-items-center mr-lg-2">-->
<!--                <i class="fab fa-youtube"></i>-->
<!--              </div>-->
            </a>
          </div>
          <div class="col-5 d-lg-none">
            <p class="text-white mb-0">{{ $t('messages.location_name') }}</p>
            <p><a class="text-white" href="mailto:contact@mariannes-life.com">contact@mariannes-life.com</a></p>
          </div>
        </div>
      </div>
      <div class="col-5 d-none d-lg-block pt-3 text-right">
        <p class="text-white mb-0">{{ $t('messages.location_name') }}</p>
        <p><a class="text-white" href="mailto:contact@mariannes-life.com">contact@mariannes-life.com</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterView"
}
</script>

<style scoped lang="scss">
.container-fluid {
  background: linear-gradient(90deg, #219A0B 50%, rgba(59, 165, 31, 0.6) 86.53%);
}

.social-circle {
  background: #FFFFFF;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  cursor: pointer;

  i {
    font-size: 20px;
    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

</style>