<template>
  <div>
    <div class="container-fluid">
      <div class="row position-fixed fixed-header">
        <div class="col-1 d-none d-md-block"></div>
        <div class="col-8 col-md-9 d-flex justify-content-between align-items-center">
          <span class="name-organization">{{ $t('messages.name_charitable_organization') }}
            <img class="heart-name" src="/files/heart3.png" alt="heart3">
          </span>
        </div>
        <div class="col-4 col-md-1 d-flex justify-content-end">
          <div class="pr-2 position-relative">
            <a @click="changeLocal('en')" class="change-lang" :class="{
              'active-lang': locale === 'en',
            }">EN</a>
          </div>
          <div class="pr-2 position-relative">
            <a @click="changeLocal('uk')" class="change-lang" :class="{
              'active-lang': locale === 'uk',
            }">UA</a>
          </div>
          <div class="position-relative">
            <a @click="changeLocal('ru')" class="change-lang" :class="{
              'active-lang': locale === 'ru',
            }">RU</a>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid p-0 header-text-title">
      <div class="row header-title no-gutters">
        <div class="col-12 d-flex justify-content-center align-items-center">
          <div class="col-10 col-md-8 p-0">
            <h1 class="header-title-text">{{ $t('messages.header_title') }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "HeaderView",
  computed: {
    ...mapGetters({
      locale: 'locale',
    }),
  },
  methods: {
    ...mapActions({
      setLocale: 'setLocale'
    }),
    changeLocal(locale) {
      this.setLocale(locale);
      this._i18n.locale = locale;
    }
  }
}
</script>

<style scoped lang="scss">

.heart-name {
  height: 35px;
  position: absolute;

  @media (max-width: 767px) {
    height: 25px;
  }
}

.header-text-title {
  margin-top: 35px;
}

.fixed-header {
  top: 0;
  background: #F5FEF3;
  width: 100%;
  z-index: 2;
  min-height: 35px;
}

.name-organization {
  color: #219A0B;
  font-size: 30px;
  line-height: 35px;
  font-weight: 400;

  @media (max-width: 767px) {
    line-height: 25px;
    font-size: 20px;
  }
}

.change-lang {
  font-weight: 550;
  font-size: 20px;
  line-height: 33px;
  color: black;

  @media (max-width: 1440px) {
    font-size: 13px;
    line-height: 27px;
  }
}

.active-lang {
  color: #5570CF;
}

div {
  .change-lang {
    cursor: pointer;
  }
}

.header-title {
  padding: 15px;
  background: linear-gradient(90deg, rgba(33, 154, 11, 0.91) 50%, rgba(59, 165, 31, 0.67) 87.22%);
}

.header-title-text {
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: white;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
}
</style>