<template>
  <div class="col-12 main-info-money overflow-hidden p-0 pt-2">
    <div class="text-center first-part text-white pt-2">{{ $t('messages.amount_of_the_fee') }}: {{ price | currency() }} USD</div>
    <div class="text-center first-part text-white pt-2">{{ $t('messages.supported') }}: {{ usersList.length }} {{ $t('messages.persons') }}</div>
    <div class="money-count pt-2 mt-3">
      <div class="text-center second-part">{{ totalPaidAmount | currency(mf) }} USD</div>
      <p class="text-center">{{ (totalPaidAmount * 100 / price).toFixed(2)  }}%</p>
    </div>
    <div class="text-center text-white pr-3 pl-3 pr-lg-5 pl-lg-5 pb-3 third-part">{{ (price - totalPaidAmount).toFixed(0) | currency()}} USD {{ $t('messages.money_not_enough') }}
    </div>
    <div @click="goBeforePay" class="btn-donate w-100 d-flex justify-content-center align-items-center p-3">
      <div class="container-heart d-flex align-items-center justify-content-center">
        <i class="fas fa-heart text-danger"></i>
      </div>
      {{ $t('messages.support') }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "InfoMoneyView",
  data() {
    return {
      price: 2300000,
      mf: {
        symbol: '',
        thousandsSeparator: ' ',
        fractionCount: 0,
        fractionSeparator: ',',
        symbolPosition: 'front',
        symbolSpacing: true,
      }
    }
  },
  computed: {
    ...mapGetters({
      totalPaidAmount: 'totalPaidAmount',
      usersList: 'usersList',
    }),
  },
  methods: {
    goBeforePay() {
      this.$emit('change', true);
    }
  }
}
</script>

<style scoped lang="scss">

.main-info-money {
  background: linear-gradient(90deg, rgba(33, 154, 11, 0.91) 50%, rgba(33, 154, 11, 0.546) 138.41%);
  border-radius: 20px;

  .first-part {
    font-size: 23px;
    line-height: 27px;
    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .second-part {
    font-size: 30px;
    line-height: 35px;
    font-weight: bold;
  }

  .third-part {
    font-size: 20px;
    line-height: 23px;
    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.money-count {
  background: rgba(183, 216, 174, 0.67);
  border-radius: 20px;
  margin: 0 23px;

  p {
    font-weight: bold;
  }
}

.btn-donate {
  cursor: pointer;
  background-color: #C01414 !important;
  color: white !important;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  text-transform: uppercase;
  animation-name: heart-bit;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  @media (max-width: 1023px) {
    font-size: 20px;
    line-height: 25px;
  }
}

.container-heart {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 100%;
  margin-right: 10px;

  i {
    font-size: 25px;
    animation-name: heart-bit;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    animation-duration: 3s;

    @media (max-width: 1023px) {
      font-size: 15px;
    }
  }

  @media (max-width: 1023px) {
    width: 30px;
    height: 30px;
  }
}

@keyframes heart-bit {
  0% {
    font-size: 25px;
    @media (max-width: 1023px) {
      font-size: 15px;
    }
  }
  50% {
    font-size: 30px;
    @media (max-width: 1023px) {
      font-size: 20px;
    }
  }
  100% {
    font-size: 25px;
    @media (max-width: 1023px) {
      font-size: 15px;
    }
  }
}

@media (max-width: 1023px) {
  @keyframes heart-bit {
    0% {
      font-size: 15px;
    }
    50% {
      font-size: 20px;
    }
    100% {
      font-size: 15px;
    }

  }
}
</style>