<template>
  <div class="collection-description p-2 pl-4 pr-4">
    <p class="text-title-1 pt-3 mb-0">{{ $t('messages.collection_description.title') }}</p>
    <hr class="mt-0">
    <p>
      <strong>
        {{ $t('messages.collection_description.text1') }}
      </strong>
      {{ $t('messages.collection_description.text2') }}
      <br><br>
      {{ $t('messages.collection_description.text3') }}
    </p>
    <img @click="viewImage('/files/photo2.jpg')" class="w-100" src="/files/photo2.jpg" alt="marianna_2">
    <p class="mt-3">
      <strong>{{ $t('messages.collection_description.text4') }}</strong>
      {{ $t('messages.collection_description.text5') }}
      <strong>{{ $t('messages.collection_description.text6') }}</strong>
      {{ $t('messages.collection_description.text7') }}
      <strong>{{ $t('messages.collection_description.text8') }}</strong>
      <br><br>
      {{ $t('messages.collection_description.text9') }}
      <strong>{{ $t('messages.collection_description.text10') }}</strong>
    </p>
    <img @click="viewImage('/files/photo3.jpg')"  class="w-100" src="/files/photo3.jpg" alt="marianna_3">
    <p class="mt-3">
      <strong>{{ $t('messages.collection_description.text11') }}
      </strong>
      <br><br>
      {{ $t('messages.collection_description.text12') }}
    </p>
    <img @click="viewImage('/files/photo4.jpg')"  class="w-100" src="/files/photo4.jpg" alt="marianna_4">
    <p class="mt-3">
      <strong>{{ $t('messages.collection_description.text13') }}</strong>
      {{ $t('messages.collection_description.text14') }}
      <strong>{{ $t('messages.collection_description.text15') }}</strong>
      {{ $t('messages.collection_description.text16') }}
      <br><br>
      {{ $t('messages.collection_description.text17') }}
      <strong>{{ $t('messages.collection_description.text18') }}</strong>
      <br><br>
      {{ $t('messages.collection_description.text19') }}
    </p>
    <img @click="viewImage('/files/photo5.jpg')"  class="w-100" src="/files/photo5.jpg" alt="marianna_5">
    <hr>
    <h5 class="text-title-1"> {{ $t('messages.documentations') }}</h5>
    <div class="row">
      <div class="col-6 p-1"><img @click="viewImage('/files/letter_from_hospital_EN.jpg')" class="w-100" src="/files/letter_from_hospital_EN.jpg" alt="doc-1"></div>
      <div class="col-6 p-1"><img @click="viewImage('/files/LLC_Baby_Neurologist_EN.jpg')" class="w-100" src="/files/LLC_Baby_Neurologist_EN.jpg" alt="doc-2"></div>
      <div class="col-6 p-1"><img @click="viewImage('/files/SM1811_Nadiia_EN.jpg')" class="w-100" src="/files/SM1811_Nadiia_EN.jpg" alt="doc-6"></div>
      <div class="col-6 p-1"><img @click="viewImage('/files/LLC_Baby_Orthopedic_trumatologist\'s_EN.jpg')" class="w-100" src="/files/LLC_Baby_Orthopedic_trumatologist's_EN.jpg" alt="doc-5">
      </div>
      <div class="col-6 p-1"><img @click="viewImage('/files/LLC_Baby_Orthopedic_trumatologist\'s_SCAN-1.jpg')" class="w-100" src="/files/LLC_Baby_Orthopedic_trumatologist's_SCAN-1.jpg" alt="doc-3">
      </div>
      <div class="col-6 p-1"><img @click="viewImage('/files/LLC_Baby_Orthopedic_trumatologist\'s_SCAN-2.jpg')" class="w-100" src="/files/LLC_Baby_Orthopedic_trumatologist's_SCAN-2.jpg" alt="doc-4">
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "DescriptionView",
  methods: {
    ...mapActions({
      viewImage: 'viewImage',
    }),
  }
}
</script>

<style scoped lang="scss">
img {
  cursor: pointer;
}
.collection-description {
  background: #f5fef3;
  box-shadow: 0 4px 4px rgba(0,0,0,.25);
  border-radius: 20px;
}
</style>