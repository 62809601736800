<template>
  <div class="photo-1">
    <img @click="viewImage('/files/photo1.png')" class="w-100" src="/files/photo1.png" alt="marianna_1">
    <span class="badge badge-danger">{{ $t('messages.fundraising') }}</span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "MainPhoto",
  methods: {
    ...mapActions({
      viewImage: 'viewImage',
    }),
  }
}
</script>

<style scoped lang="scss">

.photo-1 {
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  span {
    background-color: #C01414 !important;
    color: white !important;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    bottom: 0;

    @media (max-width: 640px) {
      font-size: 12px;
      line-height: 16px;
      padding: 10px;
      position: relative;
      bottom: 2px;
    }

    @media (min-width: 641px) AND (max-width: 1023px) {
      font-size: 16px;
      line-height: 19px;
    }
  }
}
</style>