<template>
  <div class="container-fluid mt-5 d-flex">
    <div class="d-none d-lg-flex">
      <div class="offset-1"></div>
      <div class="col-4">
        <div class="row no-gutters">
          <InfoMoneyView id="btn-donate-1" @change="goBeforePay" v-if="!pay"/>
          <BeforePay id="before-pay-1" v-if="pay"/>
          <OwnerView class="mt-5"/>
          <DonatesView class="mt-5"/>
        </div>
      </div>
      <div class="col-6">
        <MainPhoto/>
        <DescriptionView class="mt-5"/>
      </div>
    </div>
    <div class="d-block d-lg-none pl-3 pr-3">
      <MainPhoto/>
      <InfoMoneyView id="btn-donate-2" @change="goBeforePay" class="mt-3" v-if="!pay"/>
      <BeforePay id="before-pay-2" class="mt-3" v-if="pay"/>
      <OwnerView class="mt-3"/>
      <DescriptionView class="mt-3"/>
      <DonatesView class="mt-3"/>
    </div>
    <div v-if="viewBtnPay" class="position-fixed w-100" style="bottom: 0">
      <div @click="goBeforePay" class="btn-donate w-100 d-flex justify-content-center align-items-center p-3">
        <div class="container-heart d-flex align-items-center justify-content-center">
          <i class="fas fa-heart text-danger"></i>
        </div>
        {{ $t('messages.support') }}
      </div>
    </div>
  </div>
</template>

<script>
import InfoMoneyView   from "@/views/Main/InfoMoneyView";
import OwnerView       from "@/views/Main/OwnerView";
import DonatesView     from "@/views/Main/DonatesView";
import MainPhoto       from "@/views/Main/MainPhoto";
import DescriptionView from "@/views/Main/DescriptionView";
import BeforePay       from "@/views/Main/BeforePay";
import { mapGetters }  from "vuex";

export default {
  name: "MainView",
  components: {
    BeforePay,
    DescriptionView,
    MainPhoto,
    DonatesView,
    OwnerView,
    InfoMoneyView,
  },
  data() {
    return {
      pay: false,
      viewBtnPay: false,
      intervalId: '',
    }
  },
  computed: {
    ...mapGetters({
    }),
  },
  mounted() {
    this.intervalId = setInterval(() => {
      this.checkViewBtnPay();
    }, 100)
  },
  methods: {
    goBeforePay(value) {
      this.pay = value;
    },
    checkViewBtnPay() {
      let el = document.getElementById('btn-donate-1');
      let footerElPosition = document.getElementById('footer').getBoundingClientRect();
      if (el) {
        el = el.getBoundingClientRect();
        if (!el.x && !el.y) {
          el = document.getElementById('btn-donate-2').getBoundingClientRect();
        }
        if ((el.bottom - 35) <= 0 && footerElPosition.top - window.innerHeight > 0) {
          this.viewBtnPay = true;
        } else {
          this.viewBtnPay = false;
        }
      } else {
        let el1 = document.getElementById('before-pay-1');
        el1 = el1.getBoundingClientRect().x && el1.getBoundingClientRect().y;
        clearInterval(this.intervalId);
        window.scrollTo(0, el1 ? 100 : 450 )
        // el.scrollIntoView({behavior: "smooth"});
        this.viewBtnPay = false;
      }

      // console.log(document.getElementById('btn-donate'));
    }
  }
}
</script>

<style scoped lang="scss">

.btn-donate {
  cursor: pointer;
  background-color: #C01414 !important;
  color: white !important;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  text-transform: uppercase;
  animation-name: heart-bit;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  @media (max-width: 1023px) {
    font-size: 20px;
    line-height: 25px;
  }
}

.container-heart {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 100%;
  margin-right: 10px;

  i {
    font-size: 25px;
    animation-name: heart-bit;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    animation-duration: 3s;

    @media (max-width: 1023px) {
      font-size: 15px;
    }
  }

  @media (max-width: 1023px) {
    width: 30px;
    height: 30px;
  }
}

@keyframes heart-bit {
  0% {
    font-size: 25px;
    @media (max-width: 1023px) {
      font-size: 15px;
    }
  }
  50% {
    font-size: 30px;
    @media (max-width: 1023px) {
      font-size: 20px;
    }
  }
  100% {
    font-size: 25px;
    @media (max-width: 1023px) {
      font-size: 15px;
    }
  }
}

@media (max-width: 1023px) {
  @keyframes heart-bit {
    0% {
      font-size: 15px;
    }
    50% {
      font-size: 20px;
    }
    100% {
      font-size: 15px;
    }

  }
}
</style>