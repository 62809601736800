<template>
  <div class="modal_custom" :class="{
    'd-flex': viewModal,
    'd-none': !viewModal,
    'modal_custom_view': modalOpacity,
  }" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="close-container position-absolute d-flex align-items-center justify-content-center">
            <button @click="close" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <img style="max-width: 100%" :style="'max-height:' + maxHeight + 'px'" :src="srcImage" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex';

export default {
  name: "Modal",
  data() {
    return {
      maxHeight: window.screen.availHeight * 85 / 100,
      modalOpacity: false,
    }
  },
  computed: {
    ...mapGetters({
      viewModal: 'viewModal',
      srcImage: 'srcImage',
    })
  },
  watch: {
    viewModal() {
      this.viewModalOpacity(this.viewModal);
    }
  },
  methods: {
    ...mapMutations({
      SET_VIEW_MODAL: 'SET_VIEW_MODAL',
    }),
    close() {
      this.SET_VIEW_MODAL(!this.viewModal);
    },
    viewModalOpacity(viewModal) {
      setTimeout(() => {
        this.modalOpacity = viewModal;
      }, 10)
    }
  },
  mounted() {
    console.log(this.maxHeight)
  }
}
</script>

<style scoped lang="scss">

.modal_custom {
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  float: left;
  opacity: 0;
  max-height: 100%;
  transition: opacity 300ms;
}

.modal_custom_view {
  opacity: 1;
}

.close-container {
  right: -5px;
  top: -15px;
  width: 40px;
  height: 40px;

  .close {
    font-size: 45px;
    color: red;
  }
}

.modal-dialog {
  @media (min-width: 1024px) {
    max-width: 75% !important;
  }
}

.modal-content {
  @media (max-width: 767px) {
    top: 10px;
  }
  display: unset!important;
}
</style>