<template>
  <div class="col-12 donates">
    <p class="text-title-1 pt-3">{{ $t('messages.benefactors') }}</p>
    <div v-for="(user, key) in usersList" :key="key">
      <hr class="">
      <div class="d-flex justify-content-between">
        <div>
          <p class="mb-1 donate-name">{{ getUserName(user.name) }}</p>
          <p class="donate-date">{{ user.create_date }}</p>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <span class="badge badge-primary p-2 pl-4 pr-4 donate-price">{{ (Number(user.amount) / 100).toFixed(2) }} {{ user.currency.toUpperCase() }}</span>
        </div>
      </div>
      <div>
        <p class="donate-date">{{ user.comment }}</p>
      </div>
    </div>
<!--    <div class="d-flex justify-content-center mt-4">-->
<!--            <span class="badge badge-primary donate-more pt-2 pb-2 pr-3 pl-3">{{ $t('messages.displayed_more') }}<i-->
<!--                class="fas fa-sort-down position-relative"></i></span>-->
<!--    </div>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DonatesView",
  computed: {
    ...mapGetters({
      usersList: 'usersList',
    })
  },
  methods: {
    getUserName(name) {
      if (name === this._i18n.messages.en.messages.anonymous_benefactor ||
          name === this._i18n.messages.ru.messages.anonymous_benefactor ||
          name === this._i18n.messages.uk.messages.anonymous_benefactor) {
        return this.$t('messages.anonymous_benefactor');
      } else {
        return name;
      }
    }
  },
}
</script>

<style scoped lang="scss">

.donates {
  background: #F5FEF3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding-left: 30px !important;
  padding-right: 15px !important;
  padding-bottom: 15px !important;
}

.donate-name {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}

.donate-date {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.donate-price {
  background: #5570CF;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.donate-more {
  cursor: pointer;
  background: #5570CF;
  border-radius: 20px;
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;

  i {
    bottom: 3px;
  }
}
</style>