<template>
  <div class="col-12 short-info-block background-shared pb-2 pb-lg-0">
    <p class="pt-3"><strong class="owner-name">{{ $t('messages.owner_name') }}</strong></p>
    <hr class="mr-4 ml-4">
    <p class="mb-0"><i class="fas fa-user-nurse mr-2"></i><strong>{{ $t('messages.diagnosis') }}:</strong></p>
    <p class="mb-4">{{ $t('messages.sma') }}</p>
    <p class="mb-0"><i class="fas fa-bullseye mr-2"></i><strong>{{ $t('messages.purpose_of_the_collection') }}:</strong></p>
    <p class="mb-4">{{ $t('messages.collection_zolgensma') }}</p>
    <p class="mb-0"><i class="far fa-compass mr-2"></i><strong>{{ $t('messages.location') }}:</strong></p>
    <p class="mb-4">{{ $t('messages.location_name') }}</p>
    <p class="mb-0"><i class="far fa-calendar-alt mr-2"></i><strong>{{ $t('messages.beginning') }}:</strong></p>
    <p class="mb-4">{{ $t('messages.beginning_date') }}</p>
  </div>
</template>

<script>
export default {
  name: "OwnerView"
}
</script>

<style scoped lang="scss">

.short-info-block {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background: #F5FEF3;

  p {
    padding-left: 30px;
    @media (max-width: 640px) {
      padding-left: 10px;
    }
  }
}

.owner-name {
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
}
</style>