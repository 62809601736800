import Vue from 'vue'
import Vuex  from 'vuex'
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    locale: 'en',
    viewModal: false,
    viewModalPay: false,
    srcImage: '',
    dataPay: {
      price: '',
      currency: 'USD',
      username: '',
      comment: '',
    },
    usersList: [],
    totalPaidAmount: 0,
    paidInfo: {
      sess_id: '',
      status: 0,
      view: 0,
    },
    stripe: {
      publicKey: ''
    }
  },
  getters: {
    locale: state => state.locale,
    viewModal: state => state.viewModal,
    viewModalPay: state => state.viewModalPay,
    srcImage: state => state.srcImage,
    dataPay: state => state.dataPay,
    totalPaidAmount: state => state.totalPaidAmount,
    usersList: state => state.usersList,
    paidInfo: state => state.paidInfo,
    stripe: state => state.stripe,
  },
  mutations: {
    SET_LOCALE(state, value) {
      state.locale = value;
    },
    SET_VIEW_MODAL(state, value) {
      state.viewModal = value;
    },
    SET_USER_LIST(state, value) {
      state.usersList = value;
    },
    SET_VIEW_MODAL_PAY(state, value) {
      state.viewModalPay = value;
    },
    SET_STRIPE_PUBLIC_KEY(state, value) {
      state.stripe.publicKey = value;
    },
    SET_TOTAL_PAID(state, value) {
      state.totalPaidAmount = value;
    },
    SET_SRC_IMAGE(state, value) {
      state.srcImage = value;
    },
    SET_DATA_PAY(state, value) {
      state.dataPay = value;
    },
    SET_PAID_INFO(state, value) {
      state.paidInfo = value;
    },
  },
  actions: {
    viewImage({commit}, src) {
      commit('SET_SRC_IMAGE', src);
      commit('SET_VIEW_MODAL', true);
    },
    setLocale({commit}, value) {
      commit('SET_LOCALE', value);
    },
    setPaidInfo({commit}, value) {
      commit('SET_PAID_INFO', value);
    },
    getTotalPaid({commit}) {
      axios.get('https://api.mariannes-life.com/getTotalPaid')
          .then(({data}) => {
            commit('SET_TOTAL_PAID', data.totalAmount / 100)
          })
    },
    getUserList({commit}) {
      axios.get('https://api.mariannes-life.com/getUsers')
          .then(({data}) => {
            commit('SET_USER_LIST', data);
            // eslint-disable-next-line no-undef
            $('#first-load').hide(true);
          })
    },
    getStripe({commit}) {
      axios.get('https://api.mariannes-life.com/getStripCredentials')
          .then(({data}) => {
            commit('SET_STRIPE_PUBLIC_KEY', data)
          })
    }
  },
  modules: {

  }
})
